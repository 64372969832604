import { Spacer } from '~/components/Common/Spacer'
import Icon from '~/components/Icon'
import { IconLoaderCircle } from '~/components/Icon/IconLoaderCircle'

interface ModalProps {
    /**
     * Dialog header
     */
    header?: boolean
    /**
     * Dialog header border
     */
    headerBorder?: boolean
    /**
     * Dialog title
     */
    title?: string
    /**
     * Dialog Size
     */
    size?: keyof typeof dialogSizeMap
    /**
     * Passing element
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Dialog action buttons
     */
    action?: JSX.Element | JSX.Element[]
    /**
     * Dialog with loading
     */
    loading?: boolean
    /**
     * Is Modal show
     */
    show: boolean
    /**
     * Show close icon
     */
    close?: boolean
    /**
     * Append footer to modal
     */
    footer?: JSX.Element
    /**
     * Option to remove dialog content padding
     */
    removeContentPadding?: boolean
    /**
     * Minimzie mode
     */
    minimize?: boolean
    /**
     * Action justify
     */
    acitonJustify?: 'end' | 'center' | 'start'
    /**
     * Minimize title
     */
    minimizeTitle?: string
    /**
     * Close Modal handler
     */
    onClose?: () => void
    /**
     * Maximize Modal handler
     */
    onMaximize?: () => void
}
export const Modal = ({
    header = true,
    headerBorder = true,
    action,
    loading = false,
    children,
    show = false,
    title,
    close,
    size = 'md',
    footer,
    removeContentPadding = false,
    minimize = false,
    acitonJustify = 'end',
    minimizeTitle,
    onClose,
    onMaximize = () => false
}: ModalProps) => {
    if (!show) {
        return <></>
    }
    const closeDialog = () => {
        if (!onClose) {
            return
        }
        onClose()
    }

    return (
        <div
            className={`fixed z-40 flex items-center justify-center transition-colors duration-300 overflow-hidden ${
                minimize
                    ? 'bottom-6 left-1/2 transform -translate-x-1/2 w-48'
                    : 'bg-neutrals-900 bg-opacity-40 w-full h-full left-0 top-0 p-4'
            } ${show ? '' : 'hidden'}`}>
            <div className="w-full max-h-full h-full overflow-hidden">
                <div className="h-full flex flex-col overflow-hidden">
                    {minimize && (
                        <div className="flex items-center py-2.5 px-4 w-48 bg-primary text-neutrals-light rounded-lg">
                            <div className="mr-2 flex-grow truncate">{minimizeTitle || title || 'Dialog'}</div>
                            <Spacer></Spacer>
                            <Icon name="open-fullscreen" onClick={onMaximize}></Icon>
                            <div className="w-2"></div>
                            <div
                                onClick={closeDialog}
                                data-testid="modal_icon"
                                role="button"
                                className="flex items-center">
                                <Icon name="x" />
                            </div>
                        </div>
                    )}

                    <div
                        data-testid="modal_size"
                        className={`rounded-lg bg-white flex-col overflow-hidden max-w-full m-auto ${
                            dialogSizeMap[size]
                        } ${minimize ? 'hidden' : 'flex '}`}>
                        {header && (
                            <div
                                className={`flex p-6 items-center ${
                                    headerBorder ? 'border-b border-neutrals-100' : ''
                                } ${!title ? 'h-14' : ''}`}>
                                <div
                                    data-testid="modal_title"
                                    className={`flex justify-between w-full  items-center font-medium text-lg text-neutrals-900`}>
                                    {title}
                                </div>
                                {close && (
                                    <div onClick={closeDialog} data-testid="modal_icon" role="button">
                                        <Icon name="x" size="lg" />
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={`flex flex-col overflow-auto ${
                                removeContentPadding ? '' : header && !title && !headerBorder ? 'pb-6 px-6' : 'p-6'
                            } items-center font-normal text-base text-neutrals-900`}
                            data-testid="content_wrapper">
                            {loading ? <IconLoaderCircle className="w-24 h-24"></IconLoaderCircle> : children}
                        </div>
                        {action && (
                            <div
                                className={`flex justify-${acitonJustify} items-center border-t border-neutrals-100 h-20`}>
                                <div className="py-4 sm:py-6 pr-6 sm:pr-6 flex" data-testid="modal_button">
                                    {action}
                                </div>
                            </div>
                        )}
                        {!!footer && (
                            <div className="contents" data-testid="footer">
                                {footer}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const dialogSizeMap = {
    xs: 'w-dialog-xs',
    sm: 'w-dialog-sm',
    md: 'w-dialog-md',
    lg: 'w-dialog-lg',
    xl: 'w-dialog-xl'
}

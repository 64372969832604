import locale from '~/locale'

export const pageMap: Record<
    string,
    {
        title?: string
        header?: string
        layout?: 'dashboard' | 'fullscreen'
        auth?: boolean
        hideNavigation?: boolean
        mobile?: boolean
    }
> = {
    '/login': {
        title: 'Masuk',
        header: '',
        layout: 'fullscreen',
        auth: false,
        mobile: true
    },
    '/maintenance': {
        title: 'Maintenance',
        header: 'Maintenance',
        layout: 'fullscreen',
        auth: false
    },
    '/business': {
        title: locale.id.common.business,
        header: locale.id.common.business,
        layout: 'dashboard',
        auth: true,
        mobile: true,
        hideNavigation: true
    },
    '/business/account': {
        title: locale.id.common.account,
        header: locale.id.common.account,
        layout: 'dashboard',
        auth: true
    },
    '/business/category': {
        title: locale.id.category.category_list.title,
        header: locale.id.category.category_list.title,
        layout: 'dashboard',
        auth: true
    },
    '/business/customer': {
        title: locale.id.common.customer,
        header: locale.id.common.customer,
        layout: 'dashboard',
        auth: true
    },
    '/business/customer-report': {
        title: locale.id.customer.report.title,
        header: locale.id.customer.report.title,
        layout: 'dashboard',
        auth: true,
        mobile: true
    },
    '/business/history': {
        title: locale.id.common.history,
        header: locale.id.common.history,
        layout: 'dashboard',
        auth: true
    },
    '/business/product': {
        title: locale.id.common.product,
        header: locale.id.common.product,
        layout: 'dashboard',
        auth: true
    },
    '/business/product/add': {
        title: locale.id.common.add_product,
        header: locale.id.common.add_product,
        layout: 'dashboard',
        auth: true
    },
    '/business/product/edit': {
        title: locale.id.common.edit_product,
        header: locale.id.common.edit_product,
        layout: 'dashboard',
        auth: true
    },
    '/business/staff': {
        title: locale.id.common.staff,
        header: locale.id.common.staff,
        layout: 'dashboard',
        auth: true
    },
    '/business/staff/add': {
        title: locale.id.common.staff,
        header: locale.id.common.staff,
        layout: 'dashboard',
        auth: true
    },
    '/business/stock-audit': {
        title: locale.id.common.stock_audit,
        header: locale.id.common.stock_audit,
        layout: 'dashboard',
        auth: true
    },
    '/business/stock-in': {
        title: locale.id.common.stock_in,
        header: locale.id.common.stock_in,
        layout: 'dashboard',
        auth: true
    },
    '/business/stock-out': {
        title: locale.id.common.stock_out,
        header: locale.id.common.stock_out,
        layout: 'dashboard',
        auth: true
    },
    '/business/supplier': {
        title: locale.id.common.supplier,
        header: locale.id.common.supplier,
        layout: 'dashboard',
        auth: true
    },
    '/business/report': {
        title: locale.id.report.title,
        header: locale.id.report.title,
        layout: 'dashboard',
        auth: true,
        mobile: true
    },
    '/business/stock-report': {
        title: locale.id.stock_report.title,
        header: locale.id.stock_report.title,
        layout: 'dashboard',
        auth: true,
        mobile: true
    },
    '/onboarding': {
        title: locale.id.onboarding.welcome,
        header: locale.id.onboarding.welcome,
        layout: 'fullscreen',
        auth: true,
        hideNavigation: true,
        mobile: true
    },
    '/business/payment/order': {
        title: locale.id.payment.order.title,
        header: locale.id.payment.order.title,
        layout: 'fullscreen',
        auth: true,
        hideNavigation: true
    },
    '/': {
        header: 'kelola.co',
        layout: 'fullscreen',
        auth: false
    },
    '/_error': {
        title: 'kelola.co',
        header: 'kelola.co',
        layout: 'fullscreen',
        auth: false
    }
}

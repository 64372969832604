import Head from 'next/head'

interface MetaProps {
    /**
     * Title
     */
    title?: string
    /**
     * Description
     */
    description?: string
    /**
     * Additional meta
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Use site name as prefix
     */
    useSiteName?: boolean
}

export const Meta = ({ title = 'Kelola Bisnis Anda', description = '', children, useSiteName = true }: MetaProps) => {
    const fullTitle = useSiteName ? title + ' | Kelola' : title
    const defaultDescription =
        'Solusi lengkap kelola kebutuhan bisnis mulai dari stok masuk, stok keluar, invoice dan laporan keuangan.'
    return (
        <>
            <Head>
                <title>{fullTitle}</title>
                <meta id="description" name="description" content={description || defaultDescription} />
                <meta id="robots" name="robots" content="noindex, nofollow" />
                <meta id="og-title" property="og:title" content={fullTitle} />
                <meta id="og-description" property="og:description" content={description || defaultDescription} />
                <meta id="twitter-title" name="twitter:title" content={fullTitle} />
                <meta id="twitter-description" name="twitter:description" content={description || defaultDescription} />
                {children}
            </Head>
        </>
    )
}

var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.0.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { ENV_STATE, IS_PROD } from './constants'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    dsn:
        SENTRY_DSN || 'https://a12dbe7dc28dbceb87af5effa1aaae24@o4508007386447872.ingest.us.sentry.io/4508007400407040',
    // Adjust this value in production, or use tracesSampler for greater control
    sampleRate: IS_PROD ? 1.0 : 1,
    tracesSampler: (samplingContext) => {
        if (samplingContext?.request?.url?.includes('/api/build-id')) {
            return 0.0
        }

        return IS_PROD ? 1.0 : 1
    },
    replaysOnErrorSampleRate: IS_PROD ? 1.0 : 1,
    replaysSessionSampleRate: IS_PROD ? 1.0 : 1,
    beforeSend: (event) => {
        if (event?.request?.url?.includes('/api/build-id')) {
            return false
        }
        return true
    },
    integrations: [
        Sentry.replayIntegration({
            maskAllInputs: true,
            maskAllText: false,
            blockAllMedia: true
        })
    ],
    environment: ENV_STATE
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
})

import * as Sentry from '@sentry/nextjs'

const setUser = (userId?: string) => {
    if (!!userId) Sentry.setUser({ id: userId })
}

const clearUser = () => {
    Sentry.setUser(null)
}

const sendSentryEvent = (message: any) => {
    Sentry.captureException(message)
}

const $sentry = {
    setUser,
    clearUser,
    sendSentryEvent
}

export default $sentry
